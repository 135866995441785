<template>
  <div class="flex flex-col items-center justify-center h-screen bg-green-custom text-white overflow-hidden">
    <div>
      <img
        alt="pickle swap"
        class="cropped-ofp"
        src="../assets/pickleswap.gif"
      >
    </div>
    <div>
      <div
        id="countdown"
        class="countdown text-4xl font-bold"
      >
        {{ timeLeft }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const timeLeft = ref('');
const targetDate = new Date(Date.UTC(2024, 5, 22, 2, 0, 0)); // Use UTC date

function updateCountdown() {
  const now = new Date().getTime();
  const distance = targetDate.getTime() - now;

  if (distance < 0) {
    timeLeft.value = 'PickleSwap deadline has passed! 🥒';
    return;
  }

  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);

  timeLeft.value = `${hours}h ${minutes}m ${seconds}s`;
}

onMounted(() => {
  updateCountdown();
  setInterval(updateCountdown, 1000);
});
</script>

<style>
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  height: 100%;
}

.cropped-ofp {
  pointer-events: none;
  width: 750px;
  height: 411px;
  object-fit: cover;
  object-position: 25% 25%;
}

@media (max-width: 768px) {
  .cropped-ofp {
    width: 100%;
    height: auto;
  }
}

#countdown {
  color: #fff;
  font-size: 3rem;
  font-weight: bold;
  margin-top: 2rem;
  text-align: center;
}

</style>
