/* eslint-disable */
import { createApp } from 'vue';
import axios from 'axios';
import SolanaWallets from 'solana-wallets-vue';
import 'solana-wallets-vue/styles.css';
import './assets/tailwind.css';
import './assets/style.css';
import {
  SolflareWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import App from './App.vue';
import router from './router';

const walletOptions = {
  wallets: [
    new SolflareWalletAdapter(),
  ],
  autoConnect: true,
};

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

const app = createApp(App);
app.config.globalProperties.$axios = axios;

app.use(router);

app.use(SolanaWallets, walletOptions);

app.mount('#app');
