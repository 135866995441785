<template>
  <div class="home">
    <TimerPage />
  </div>
</template>

<script>
import TimerPage from '@/components/TimerPage.vue';

export default {
  name: 'HomeView',
  components: {
    TimerPage,
  },
};
</script>

<style scoped>
.home {
  text-align: center;
}
</style>
